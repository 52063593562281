// utils.js
import { getOpenId, wxSdk } from '@/api/api'
const seo = require('../../config/index')
import wx from "weixin-js-sdk";

// 获取微信openid
export function getopenIdByCode() {
    if(window.location.href.includes('code=')){
        let code = window.location.href.split('code=')[1].split('&')[0]
        getOpenId({ code: code }).then(res=>{
            localStorage.setItem('openid', res.data.openid)
        })
    }
}

// 微信重定向至已验证安全域名
export function getBaseInfos(fromPath) {
    let url = seo.config.homeUrl
    url += fromPath
    var url_code = "https://open.weixin.qq.com/connect/oauth2/authorize?appid="+ seo.config.wxConfig.appId +"&redirect_uri=" + url + "&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect";
    window.location.href = url_code;//打开这个链接，你的url后面就会跟上code的参数
}

// 判断微信环境
export function isWeiXinUA() {
  let ua = window.navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
      return true;
  } else {
      return false;
  }
}

// 微信分享初始化
export function wxSdkShare(params){
    let url = params.url
    let context = params.context
    wxSdk({ url:url }).then(res=>{
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: params.appId, // 必填，公众号的唯一标识
        timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
        nonceStr: res.data.data.noncestr, // 必填，生成签名的随机串
        signature: res.data.data.signature,// 必填，签名
        jsApiList: ['checkJsApi', 'updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareAppMessage','onMenuShareTimeline', 'onMenuShareQQ', 'onMenuShareQZone'] // 必填，需要使用的JS接口列表
      });
      wx.ready(function () {
        wx.checkJsApi({
          jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareAppMessage','onMenuShareTimeline', 'onMenuShareQQ', 'onMenuShareQZone'], // 必填，需要使用的JS接口列表
          success: function(res) {
            // “分享给朋友”及“分享到QQ”
            if (res.checkResult.updateAppMessageShareData || res.checkResult.onMenuShareAppMessage) {
              wx.updateAppMessageShareData({ 
                title: params.title, // 分享标题
                desc: context, // 分享描述
                link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
                imgUrl:  params.imgUrl, // 分享图标
                success: function () {
                  // 设置成功
                }
              })
            }
            // “分享到朋友圈”及“分享到 QQ 空间”
            if(res.checkResult.updateTimelineShareData || res.checkResult.onMenuShareTimeline){
              wx.updateTimelineShareData({ 
                title: params.title, // 分享标题
                link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
                imgUrl: params.imgUrl, // 分享图标
                success: function () {
                  // 设置成功
                }
              })
            }
        }})
      })
    })
}