<template>
    <div class="wrap">
        <div class="header">
            <div class="text">超级会员</div>
            <div style="width: fit-content; position: absolute; top: 8px;left: 10px;" @click="goUserPage()">
                <img src="../../assets/images/back.png" alt="" width="24px" height="24px" style="padding-top:3px;">
            </div>
        </div>
        <div class="content">
            <div class="card_wrap">
                <div v-for="(list,index) in lists" :key="list.id" :class="list.isSelected ? 'card-active' : 'card'" @click="handleClick(list)">
                    <div class="discount" v-if="index < 2">限时秒杀</div>
                    <div class="discount" v-else-if="index === 3">新人特惠</div>
                    <div class="nodiscount" v-else></div>
                    <div class="vip_type">{{ list.vipType }}</div>
                    <div class="price_wrap">
                      <div class="new_user_price"><span style="font-size:16px">￥</span>{{ list.newUserPrice }}</div>
                      <div class="default_price"><span style="font-size:16px">￥</span>{{ list.defaultPrice }}</div>
                    </div>
                    <div class="cost_wrap">
                      <div class="day_text">{{ list.text }}</div>
                      <span style="color: #b57d1d;font-size:12px;">|</span>
                      <div class="day_cost">{{ list.dayCost }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="coupon-row">
            <div class="coupon" @click="selectCoupon">
                <span>{{ couponText }}</span>
                <img src="../../assets/images/enter.png" alt=""  width="20" height="20">
            </div>
        </div>
        <div class="row">
            <div :class="isSelected ? 'activate-now' : 'activate-now-grey'" @click="activateNow">立即开通</div>
        </div>
        <div class="footer">
            <el-carousel height="130px" direction="vertical" :autoplay="true" :interval=5000>
              <el-carousel-item v-for="list in UserPayLists" :key="list.id">
                  <div class="text_wrap">
                    <div class="text">{{ list.userA }}</div>
                    <div class="text">{{ list.userB }}</div>
                    <div class="text">{{ list.userC }}</div>
                  </div>
              </el-carousel-item>
            </el-carousel>
        </div>
        <van-popup v-model="showPopup" position="bottom" round :style="'height: 50%;max-width:625px;'" @close="handleClose" closeable>
          <div class="pay-details">
            <div class="title" v-if="!isPayCancel">请选择支付方式</div>
            <div class="title" v-else>请在<van-count-down :time="time" @finish="finish"/>分钟内支付</div>
            <div class="wechat-pay" @click="wechatPayClick">
                <div class="row">
                    <img src="../../assets/images/wechat-pay.png" alt="" width="35" height="35">
                    <span>微信</span>
                </div>
                <van-checkbox v-model="wechatChecked" disabled></van-checkbox>
            </div>
            <div class="alipay" @click="alipayClick" v-show="!isWeiXin">
                <div class="row">
                    <img src="../../assets/images/alipay.png" alt="" width="35" height="35">
                    <span>支付宝</span>
                </div>
                <van-checkbox v-model="alipayChecked" disabled></van-checkbox>
            </div>
            <div class="button-row">
                <div class="button-buy" @click="submitOrder" >
                    <van-loading color="white" size="24px" v-show="isLoading"/>
                    <span v-show="!isLoading">{{ alipayChecked ? '支付宝' : '微信' }}付款</span>
                </div>
            </div>
          </div>
        </van-popup>
    </div>
</template>

<script>
import {Toast} from 'vant'
import moment from 'moment'
import wx from "weixin-js-sdk";
import { getopenIdByCode, getBaseInfos, wxSdkShare } from '@/utils/index';
const seo = require('../../../config/index')
import { wxSdk, submitWechatPay, updateOrderByOid, createOrder, updateVipAsset, queryCouponByCode, redeemCouponsByCoid,
         queryOrderById, queryUserByUid } from '@/api/api'
export default {
    name: "Activesvip",
    mounted(){
        this.getUserLists()
        this.isWeiXin = this.isWeiXinUA()
        if(this.isWeiXin){
            this.wxShareInit()
        }
        this.getCouponInfo()
        // 二级域名重定向至可付款域名，并携带userInfo
        this.partnerRedirect()
        // 默认选择第一个
        this.handleClick(this.lists[0])
        this.routerTimer = setTimeout(()=>{
            if(sessionStorage.getItem('promoCode')){
                this.$router.push({ path:'/activesvip', query:{ promo_code: sessionStorage.getItem('promoCode') } })
                sessionStorage.removeItem('promoCode')
            }
        },0)
    },
    destroyed(){
        clearTimeout(this.routerTimer)
    },
    data() {
        return {
            lists:[{
                id:'new_partner',
                vipType: '年卡会员',
                // newUserPrice: 1.6,
                newUserPrice: 169,
                defaultPrice: 238,
                text: '日均最低',
                dayCost: '约￥'+String((169 / 365).toFixed(2)),
                isSelected: false, // 是否被选中
            },{
                id:'new_quarter_member',
                vipType: '季卡会员',
                // newUserPrice: 0.8,
                newUserPrice: 89,
                defaultPrice: 128,
                text: '日均最低',
                dayCost: '约￥'+String((89 / 90).toFixed(2)),
                isSelected: false, // 是否被选中
            },{
                id:'new_month_member',
                vipType: '月卡会员',
                // newUserPrice: 0.4,
                newUserPrice: 49,
                defaultPrice: 88,
                text: '日均最低',
                dayCost: '约￥'+String((49 / 30).toFixed(2)),
                isSelected: false, // 是否被选中
            },{
                id:'new_weekly_member',
                vipType: '周卡会员',
                newUserPrice: 29,
                // newUserPrice: 0.2,
                defaultPrice: 49,
                text: '日均最低',
                dayCost: '约￥'+String((30 / 7).toFixed(2)),
                isSelected: false, // 是否被选中
            },{
                id:'9999times',
                vipType: '9999次卡',
                // newUserPrice: 1.6,
                newUserPrice: 169,
                defaultPrice: 238,
                text: '每次消耗',
                dayCost: '约￥'+String((169 / 9999).toFixed(2)),
                isSelected: false, // 是否被选中
            },{
                id:'999times',
                vipType: '999次卡',
                // newUserPrice: 0.2,
                newUserPrice: 29,
                defaultPrice: 49,
                text: '每次消耗',
                dayCost: '约￥'+String((29 / 999).toFixed(2)),
                isSelected: false, // 是否被选中
            }],
            UserPayLists:[],
            isSelected: false, // 记录是否有勾选
            showPopup: false, // 支付弹窗
            isPayCancel: false,
            time: 0,
            alipayChecked: false,
            wechatChecked: true,
            oid: '', // 订单号
            isWeiXin: false,
            isLoading: false,
            orderInfo:{
                gid:'',
                memberId: '', // 会员类别id
                title:'', // 会员类别
                type:'vip', // 款式标注为vip
                expressCharge: 0,  // 配送费
                originalCost: 0,  // 商品单价
                totalPrice: 0, // 商品单价 * 下单商品数量
                finalPayPrice: 0, // 成交价
                discount: 0, // 折扣
                couponDiscount: 0, // 优惠券的折扣
                couponDiscountType: 1, // 1-立减券，2-折扣券
                createAt:'',
                amount:1,
                imgUrl: '/public/images/vip.png',
            },
            couponText: '使用优惠券',
            isLogin: localStorage.getItem('token') ? true : false,
            userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo'))[0] : {}
      };
    },
    methods: {
        partnerRedirect(){
            // 保存userInfo
            if(this.$route.query.uid && this.$route.query.uid.length > 0){
                this.getUserInfo(Number(this.$route.query.uid))
            }
            // 重定向
            if(this.isWeiXinUA()){
                if(!localStorage.getItem('openid') || localStorage.getItem('openid') === 'undefined'){
                    const uidTemp = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo'))[0].uid : ''
                    let promo_code = this.$route.query.promo_code ? this.$route.query.promo_code : ''
                    let url;
                    if(promo_code.length > 0){
                        url = seo.config.homeUrl + '/activesvip?promo_code=' + promo_code + 'uid=' + uidTemp;
                    } else {
                        url = seo.config.homeUrl + '/activesvip?uid=' + uidTemp;
                    }
                    window.location.href = url;
                    setTimeout(()=>{
                        getBaseInfos('/activesvip')
                        getopenIdByCode()
                    },0)
                }
            }
        },
        wxShareInit(){
            let params = {}
            params.url = seo.config.wxConfig.activesvipPage.link + this.$route.fullPath
            params.context = seo.config.wxConfig.activesvipPage.desc
            params.title = seo.config.wxConfig.activesvipPage.title
            params.imgUrl = seo.config.wxConfig.activesvipPage.imgUrl
            params.appId = seo.config.wxConfig.appId
            wxSdkShare(params)
        },
        getUserInfo(uid){
            queryUserByUid({uid: uid}).then((res)=>{
                    localStorage.setItem('userInfo', JSON.stringify([res.data.data]))
                    // login({ uid: res.data.data[0].uid }).then(result=>{
                    //     localStorage.setItem('token', result.data.token)
                    // })
                    localStorage.setItem('token','13545435213521215')
            })
        },
        getUserLists(){
            let UserPayListsTemp = []
            for(let j=0; j<100; j++){
                let UserPayList = {}
                for(let i=0; i < 3; i++){
                    UserPayList.userA = this.getUserList()
                    UserPayList.userB = this.getUserList()
                    UserPayList.userC = this.getUserList()
                }
                UserPayList.id = j
                UserPayListsTemp.push(UserPayList)
            }
            this.UserPayLists = UserPayListsTemp
        },
        getUserList(){
            let vipType = [ '年卡会员', '季卡会员', '月卡会员' ]
            let middleString = '***开通了'
            let endString = vipType[Math.floor(Math.random()*vipType.length)]
            let length = [2, 3, 4][Math.floor(Math.random()*vipType.length)]
            let firstString = this.getRandomString(length)
            return firstString + middleString + endString
        },
        getRandomString(len){
            let _charStr = 'abacdefghjklmnopqrstuv浪时间可爱帅气蟋蟀热为难我看wxyzABCDEFGHJK爱佳佳孙李陈曹张方LMNOPQRST和UVWXYZ0123456789',
            min = 0, 
            max = _charStr.length-1, 
            _str = ''; 
            len = len || 2;
            for(var i = 0, index; i < len; i++){
                index = (function(randomIndexFunc, i){         
                            return randomIndexFunc(min, max, i, randomIndexFunc);
                        })(function(min, max, i, _self){
                            let indexTemp = Math.floor(Math.random()*(max-min+1)+min),
                                numStart = _charStr.length - 10;
                            if(i==0&&indexTemp >=numStart){
                                indexTemp = _self(min, max, i, _self);
                            }
                            return indexTemp ;
                        }, i);
                _str += _charStr[index];
            }
            return _str;
        },
        goUserPage(){
            this.$router.push({ path:'/personal' })
        },
        getCouponInfo(){
            if(this.$route.query.couponCode){
                queryCouponByCode({ coupon_code: this.$route.query.couponCode, uid: this.userInfo.uid }).then(res=>{
                    this.orderInfo.couponDiscount = Number(res.data.data[0].discount_amount)
                    this.orderInfo.couponDiscountType = Number(res.data.data[0].type)
                    if(this.orderInfo.couponDiscountType === 1){
                        this.couponText = '已优惠'+ Number(res.data.data[0].discount_amount) + '元'
                    } else if(this.orderInfo.couponDiscountType === 2){
                        this.couponText = '已使用'+ Number(res.data.data[0].discount_amount) + '折券'
                    }
                })
            }
        },
        handleClick(list){
            // 已勾选
            this.isSelected = true
            // 1-立减券 2-折扣券
            if(this.orderInfo.couponDiscountType === 1){
                this.orderInfo.finalPayPrice = list.newUserPrice - this.orderInfo.couponDiscount // 成交价
            } else {
                this.orderInfo.finalPayPrice = list.newUserPrice * this.orderInfo.couponDiscount * 0.1 // 成交价
            }
            this.orderInfo.originalCost = list.defaultPrice // 商品单价
            this.orderInfo.totalPrice = list.newUserPrice // 商品单价 * 下单商品数量
            this.orderInfo.discount = list.newUserPrice - this.orderInfo.finalPayPrice // 总价减成交价
            this.orderInfo.title = list.vipType
            this.orderInfo.memberId = list.id
            // 选中后改变isSelected值
            this.lists.forEach(item => {
                if (item.id === list.id) {
                    this.$set(item, 'isSelected', true);
                } else {
                    this.$set(item, 'isSelected', false);
                }
            });
        },
        // 使用优惠券
        selectCoupon(){
            sessionStorage.setItem('fromPath', 'activesvip')
            if(this.isLogin){
                if(this.$route.query.promo_code){
                    // range为1-开通会员 2-加入合伙人
                    this.$router.push({ path:'/coupon', query:{ promo_code: this.$route.query.promo_code, range: 1 } })
                } else {
                    this.$router.push({ path:'/coupon', query:{ range: 1 } })
                }
            } else {
                if(this.$route.query.promo_code){
                    sessionStorage.setItem('promoCode', this.$route.query.promo_code)
                }
                this.$router.push({ name:'Login', params:{ text: '用卡券' }})
            }
        },
        // 立即开通按钮submit
        activateNow(){
            if(this.isLogin){
                if(this.isSelected){
                    this.showPopup = true
                } else {
                    Toast('请选择');
                }
            } else {
                sessionStorage.setItem('fromPath', 'activesvip')
                if(this.$route.query.promo_code){
                    sessionStorage.setItem('promoCode', this.$route.query.promo_code)
                }
                this.$router.push({ name:'Login', params:{ text: '开会员' }})
            }
        },
        handleClose(){
            this.showPopup = false
            queryOrderById({ oid: this.oid }).then((res)=>{
                if(Object.keys(res.data.data).length > 0){
                    sessionStorage.setItem('fromPath', 'activesvip')
                    this.$router.push('/order')
                }
            })
        },
        finish(){
            this.showPopup = false
        },
        wechatPayClick(){
            this.alipayChecked = false
            this.wechatChecked = true
        },
        alipayClick(){
            if(!this.isWeiXin){
                this.alipayChecked = true
                this.wechatChecked = false
            }
        },
        isWeiXinUA() {
            let ua = window.navigator.userAgent.toLowerCase();
            if (ua.match(/MicroMessenger/i) == 'micromessenger') {
                // 微信环境保存推广码
                if(this.$route.query.promo_code){
                    sessionStorage.setItem('promoCode', this.$route.query.promo_code)
                }
                return true;
            } else {
                return false;
            }
        },
        submitOrder(){
            let params = {}
            this.oid = new Date().getTime() // 生成订单号
            params.oid =  this.oid
            params.price = this.orderInfo.finalPayPrice
            params.title = this.orderInfo.title
            params.openid = localStorage.getItem('openid') ? localStorage.getItem('openid') : ''
            this.createVipOrder() // 创建会员订单
            if(this.orderInfo.finalPayPrice > 0){
                this.isLoading = true
                if(this.isWeiXin){
                    submitWechatPay(params).then(res=>{
                        this.wxSdk(res.data)
                    })
                } else {
                    Toast('请前往公众号支付');
                    this.isLoading = false
                }
            }
        },
        // 创建会员订单
        createVipOrder(){
            let paramsVal = {}
            paramsVal.oid = this.oid
            paramsVal.nickname = this.userInfo.nickname
            paramsVal.phone_num = this.userInfo.phoneNum
            paramsVal.address = ''
            paramsVal.uid = this.userInfo.uid
            paramsVal.shop = seo.config.shopName
            paramsVal.title = this.orderInfo.title
            paramsVal.imgUrl = this.orderInfo.imgUrl
            paramsVal.type = this.orderInfo.type
            paramsVal.price = this.orderInfo.originalCost
            paramsVal.amount = this.orderInfo.amount
            paramsVal.total_price = this.orderInfo.totalPrice
            paramsVal.discount = this.orderInfo.discount
            paramsVal.parcel_fee = 0
            paramsVal.finally_price = this.orderInfo.finalPayPrice
            paramsVal.payment = '1'  // 付款方式，1-wechat、2-alipay 默认微信支付
            paramsVal.pay_time = ''  // 订单只是提交，暂未支付
            paramsVal.order_status = 'orderPaying' // 待付款状态
            createOrder(paramsVal) // 创建订单
        },
        wxSdk(payRes){
            let url = seo.config.homeUrl + this.$route.fullPath
            let my = this
            wxSdk({ url:url }).then(res=>{
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: seo.config.wxConfig.appId, // 必填，公众号的唯一标识
                    timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
                    nonceStr: res.data.data.noncestr, // 必填，生成签名的随机串
                    signature: res.data.data.signature,// 必填，签名
                    jsApiList: ["checkJsApi", "chooseWXPay", "updateAppMessageShareData", "updateTimelineShareData"] // 必填，需要使用的JS接口列表
                });
                wx.ready(function() {
                    /* 微信支付 */
                    wx.chooseWXPay({
                        appId: payRes.appId,
                        timestamp: payRes.timeStamp, // 支付签名时间戳，注意微信 jssdk 中的所有使用 timestamp 字段均为小写。但最新版的支付后台生成签名使用的 timeStamp 字段名需大写其中的 S 字符
                        nonceStr: payRes.nonceStr, // 支付签名随机串，不长于 32 位
                        package: payRes.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                        signType: payRes.signType, // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
                        paySign: payRes.paySign, // 支付签名
                        // 支付成功
                        success: function (res) {
                            console.log('支付成功', res)
                            if(res.errMsg === 'chooseWXPay:ok'){
                                let payment = '1' // 1-微信支付，2-支付宝支付
                                let payTime = moment(payRes.timeStamp).format("YYYY-MM-DD HH:mm:ss");
                                // 更新用户权益
                                updateVipAsset({ uid: my.userInfo.uid, memberId: my.orderInfo.memberId})
                                updateOrderByOid({ oid: my.oid, order_status: 'orderComment', payment: payment, pay_time:payTime }).then(()=>{
                                    my.$router.push({ path: '/pay-result', query:{ memberId: my.orderInfo.memberId } })
                                    my.isLoading = false
                                })
                                // 核销优惠券
                                if(my.orderInfo.couponDiscount > 0){
                                    redeemCouponsByCoid({ code: my.$route.query.couponCode })
                                }
                            }
                        },
                        // 支付取消
                        cancel: function(res){
                            if(res.errMsg === 'chooseWXPay:cancel'){
                                Toast('支付已取消');
                                // 设置15分钟倒计时time
                                my.time = 15 * 60 * 1000
                                my.isPayCancel = true
                                my.isLoading = false
                            }
                        },
                        // 支付失败
                        fail: function(res){
                            console.log(res)
                            Toast('支付失败');
                            my.isLoading = false
                        }
                    });
                })
            })
        },
    },
  };
  </script>
  
  <style scoped lang="scss">
.wrap {
    position: relative;
    .header {
        color: #402200;
        font-weight: 700;
        line-height: 0px;
        border-top: 50px solid #e9b966;
        border-left: 25px solid transparent;
        border-right: 25px solid transparent;
        border-image: -webkit-linear-gradient(49deg,#f5deb7,#e9b966) 20 20;
        border-image: -moz-linear-gradient(49deg,#f5deb7,#e9b966) 20 20;
        border-image: -o-linear-gradient(49deg,#f5deb7,#e9b966) 20 20;
        border-image: linear-gradient(49deg,#f5deb7,#e9b966) 20 20;
        height: 0;
        .text {
            position: absolute;
            top: 25px;
            left:0;
            right:0;
            margin: auto;
        }
    }
    .content {
        display: flex;
        flex-wrap: wrap;
        margin: 10px 5px;
        .card_wrap {
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              margin: 5px 0;
              .card {
                  background: #fffcf5;
                  border: 2px solid #eabb6a;
                  width: calc((100% - 42px) / 3);
                  border-radius: 10px;
                  margin: 10px 5px;
                  padding: 0;
                  cursor: pointer;
              }
              .card-active {
                  background: #fffcf5;
                  border: 2px solid #fe2b38;
                  width: calc((100% - 42px) / 3);
                  border-radius: 10px;
                  margin: 10px 5px;
                  padding: 0;
                  cursor: pointer;
              }
              .discount {
                  padding: 0 6px;
                  width: 50%;
                  color: #fff;
                  font-size: 12px;
                  line-height: 20px;
                  border-radius: 6.75px 0 6.75px 0;
                  background: #fe2b38;
                  background-image: linear-gradient(139deg,#ff715c,#fe2b38);
              }
              .nodiscount {
                  height: 20px;
                  border-radius: 6.75px 0 6.75px 0;
              }
              .vip_type {
                  color: #03081a;
                  font-size: 16px;
                  font-weight: 700;
                  padding: 0 0 5px 0;
              }
              .price_wrap {
                  display: flex;
                  width: 100%;
                  .new_user_price {
                      font-size: 26px;
                      font-weight: 700;
                      color: #f60;
                      vertical-align: baseline;
                      width: 50%;
                      text-align: right;
                  }
                  .default_price {
                      margin-left: 4px;
                      line-height: 20px;
                      padding-top: 5px;
                      font-size: 14px;
                      width: calc(50% - 4px);
                      text-align: left;
                      text-decoration: line-through;
                      vertical-align: baseline;
                      color: rgba(255,102,0,.35);
                  }
              }
              .cost_wrap{
                  display: flex;
                  width: 100%;
                  text-align: center;
                  background: #ffe8c0;
                  line-height: 26px;
                  margin: 5px 0 0 0;
                  border-bottom-left-radius: 9px;
                  border-bottom-right-radius: 9px;
                  .day_text {
                      color: #b57d1d;
                      font-size: 12px;
                      width: 50%;
                  }
                  .day_cost {
                      color: #b57d1d;
                      font-size: 12px;
                      width: 50%;
                  }
              }
        }
    }
    .row {
      display: flex;
      justify-content: center;
      .activate-now {
          width: 75%;
          background: rgb(233, 185, 102);
          padding: 10px 0;
          margin: 0 0 20px 0;
          border-radius: 20px;
          color: #402200;
          font-weight: 600;
      }
      .activate-now-grey {
          width: 75%;
          background: rgba(233, 185, 102, 0.5);
          padding: 10px 0;
          margin: 0 0 20px 0;
          border-radius: 20px;
          color: rgba(64, 34, 0, 0.5);
          font-weight: 600;
      }
    }
    .coupon-row {
        background: #fffcf5;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 10px 10px;
        margin: 0 15px 20px;
        border: .1px solid rgba(234, 187, 106, 0.2);
        border-radius: 5px;
        .coupon {
            display: flex;
            align-items: center;
            font-size: 16px;
            color: #969699;
        }
    }
    .footer {
        margin: 0 15px;
        border-radius: 15px;
        .text_wrap {
            margin: 15px 0;
            .text {
                font-size: 12px;
                color: #666;
                background: #eee;
                width: fit-content;
                margin: 10px auto;
                padding: 5px 10px;
                border-radius: 10px;
            }
        }
    }
}

.pay-details {
    padding: 15px 20px 30px;
    .title {
        padding: 0 0 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .alipay {
        display: flex;
        justify-content: space-between;
        font-size: 18px;
        .row {
            display: flex;
            align-items: center;
            img {
                padding-right: 10px;
            }
        }
    }
    .wechat-pay {
        display: flex;
        justify-content: space-between;
        font-size: 18px;
        .row {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            img {
                padding-right: 10px;
            }
        }
    }
    .button-row {
        position: fixed;
        bottom: 10px;
        right: 0;
        z-index: 2;
        display: flex;
        justify-content: center;
        padding: 10px;
        padding-bottom: calc(-10px + constant(safe-area-inset-bottom));
        padding-bottom: calc(-10px + env(safe-area-inset-bottom));
        background: #fff;
        left: 0;
        box-sizing: border-box;
        max-width: 625px;
        margin: 0 auto;
        .button-buy {
            width: 80%;
            height: 38px;
            display: flex;
            line-height: 30px;
            border-radius: 30px;
            align-items: center;
            font-size: 16px;
            color: #fff;
            background: rgb(233, 185, 102);
            justify-content: center;
            border: 1px solid rgb(233, 185, 102);
        }
    }
}

.el-carousel__item {
    background-color: #fff;
    border-radius: 15px;
}
::v-deep .el-carousel__indicator--vertical {
    padding: 4px 12px;
    display: none;
}
::v-deep .van-count-down {
  color: #f0061d;
  font-size: 20px;
  line-height: 20px;
  padding: 0 10px;
}
  </style>